import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { defineCustomElements } from '@ionic/pwa-elements/loader';
import 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

// import LogRocket from 'logrocket';

// additional logging for firebase -
// import * as firebase from "firebase";
// firebase.firestore.setLogLevel("debug");

if (environment.production) {
  // LogRocket.init('7paqf2/lawnvalue-production');
  enableProdMode();
// } else {
  // LogRocket.init('7paqf2/lawnvalue-staging');
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.log(err));

// Call the element loader after the platform has been bootstrapped
defineCustomElements(window);
