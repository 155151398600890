import { Component, ViewEncapsulation } from '@angular/core';
// import { AngularFireAuth } from '@angular/fire/auth';
// import LogRocket from 'logrocket';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent {
  // constructor(auth: AngularFireAuth) {
  //   auth.onAuthStateChanged((auth) => {
  //     if (auth) {
  //       LogRocket.identify(auth.uid, {
  //         email: auth.email,
  //         name: auth.displayName,
  //       });
  //     }
  //   });
  // }
}
