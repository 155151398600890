import { Notifier } from '@airbrake/browser';
import { ErrorHandler } from '@angular/core';
import { environment } from 'src/environments/environment';
import StackdriverErrorReporter from 'stackdriver-errors-js';

export class ErrorReporting implements ErrorHandler {
  reporter: any;
  airbrake: Notifier;

  constructor() {
    this.reporter = new StackdriverErrorReporter();
    this.reporter.start({
      key: environment.stackdriver.key,
      projectId: environment.stackdriver.projectId,
    });

    this.airbrake = new Notifier({
      projectId: 315027,
      projectKey: '0966e9c350c4e557f3c3628e462f535b',
      environment: 'production',
    });
  }

  handleError(error) {
    this.reporter.report(error);
    this.airbrake.notify(error);
  }
}
