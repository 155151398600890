import { HttpClientModule } from '@angular/common/http';
import {
  CUSTOM_ELEMENTS_SCHEMA,
  ErrorHandler,
  isDevMode,
  NgModule,
} from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAnalyticsModule } from '@angular/fire/analytics';
import { AngularFireAuth } from '@angular/fire/auth';
import {
  AngularFirestoreModule,
  SETTINGS as FIRESTORE_SETTINGS,
} from '@angular/fire/firestore';
import {
  AngularFireFunctionsModule,
  ORIGIN as FUNCTIONS_ORIGIN,
} from '@angular/fire/functions';
import { AngularFirePerformanceModule } from '@angular/fire/performance';
import {
  AngularFireRemoteConfigModule,
  SETTINGS as REMOTE_CONFIG_SETTINGS,
} from '@angular/fire/remote-config';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { environment } from '../environments/environment';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ErrorReporting } from './error-reporting.module';

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireRemoteConfigModule,
    AngularFireAnalyticsModule,
    AngularFireFunctionsModule, 
    AngularFirePerformanceModule,
    AngularFireStorageModule,
    AngularFirestoreModule,
    IonicModule.forRoot({
      mode: 'md',
    }),
    AppRoutingModule,
    BrowserModule,
    FormsModule,
  ],

  schemas: [CUSTOM_ELEMENTS_SCHEMA],

  providers: [
    ...(environment.production
      ? [{ provide: ErrorHandler, useClass: ErrorReporting }]
      : []),
    ...(environment.functionsOrigin
      ? [{ provide: FUNCTIONS_ORIGIN, useValue: environment.functionsOrigin }]
      : []),
    ...(environment.firestoreSettings
      ? [
          {
            provide: FIRESTORE_SETTINGS,
            useValue: environment.firestoreSettings,
          },
        ]
      : []),
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    {
      provide: REMOTE_CONFIG_SETTINGS,
      useFactory: () =>
        isDevMode() ? { minimumFetchIntervalMillis: 10_000 } : {},
    },
    AngularFireAuth,
  ],

  bootstrap: [AppComponent],
})
export class AppModule {}
